import React, { useState } from "react";
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
  Grid,
  Snackbar,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FAQs = () => {
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleSendMessage = () => {
    // Handle sending the message, e.g., sending to an API or email service
    console.log("Message sent:", message);
    setMessage(""); // Clear the message box after sending
    setOpenSnackbar(true); // Show success popup
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Frequently Asked Questions (FAQs)
      </Typography>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6">
            What is Elite project management?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Elite project management is an online marketplace offering a wide
            range of products, from electronics to fashion and home goods. Our
            goal is to provide a seamless shopping experience for our customers.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* More FAQ Accordions here */}

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6a-content"
          id="panel6a-header"
        >
          <Typography variant="h6">
            How do I contact customer support?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can reach our customer support team via email at
            info@rubyshoping.online. We aim to respond to all inquiries within
            24 hours.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Message Box Section */}
      <Typography variant="h5" gutterBottom style={{ marginTop: "2rem" }}>
        Have a Question? Send Us a Message
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Your Message"
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSendMessage}
            disabled={!message.trim()}
          >
            Send Message
          </Button>
        </Grid>
      </Grid>

      {/* Success Snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Your message has been sent successfully!
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default FAQs;
