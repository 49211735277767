import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer
      style={{
        backgroundColor: "#f8f9fa",
        padding: "20px 0",
        marginBottom: "3rem",
      }}
    >
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              Elite project management
            </Typography>
            <Typography variant="body2" color="textSecondary">
              © {new Date().getFullYear()} Elite project management. All rights
              reserved.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              Quick Links
            </Typography>
            <ul style={{ listStyle: "none", padding: 0 }}>
              <li>
                <Link
                  to="/PrivacyPolicy"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  to="/terms-and-conditions"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Terms and Conditions
                </Link>
              </li>
              <li>
                <Link
                  to="/refund&Cancellation-policy"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Refund & Cancellation Policy
                </Link>
              </li>
              <li>
                <Link
                  to="/Shipping&Delivery"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Shipping & Delivery
                </Link>
              </li>
              <li>
                <Link
                  to="/Disclaimer"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Disclaimer
                </Link>
              </li>
              <li>
                <Link
                  to="/ContactUs"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom style={{ marginTop: "1rem" }}>
              FAQs
            </Typography>
            <ul style={{ listStyle: "none", padding: 0 }}>
              <li>
                <Link
                  to="/FAQs"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Frequently Asked Questions
                </Link>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
