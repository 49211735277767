import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaCheckCircle } from 'react-icons/fa'; // Import the success icon from react-icons
import './AfterPaymentSuccess.css'; // Optional: Import CSS file for styling

const AfterPaymentSuccess = () => {
  const { paymentId } = useParams(); // Extract payment ID from the URL
  const navigate = useNavigate(); // Initialize useNavigate
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Function to fetch payment status
    const fetchPaymentStatus = async () => {
      const email = localStorage.getItem('email'); // Retrieve the email from localStorage
      try {
        const response = await axios.get(`https://api.rubyshoping.online/api/redirect_url/${paymentId}/${email}`);
        console.log("response.data", response.data);
        localStorage.removeItem('email'); // Remove the email from localStorage after fetching payment status
        setPaymentStatus(response.data.result.data); // Update state with the correct data structure
      } catch (err) {
        setError('Failed to fetch payment status. Please try again.');
      } finally {
        setLoading(false);
        localStorage.removeItem('email'); // Remove the email from localStorage after fetching payment status
      }
    };

    if (paymentId) {
      fetchPaymentStatus();
    }
  }, [paymentId]);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="success-container">
      <div className="success-card">
        {paymentStatus?.state === 'COMPLETED' ? (
          <>
            <FaCheckCircle className="success-icon" />
            <h2>Payment Successful!</h2>
            <div className="success-details">
              <h3>Transaction Details</h3>
              <p>Transaction ID: {paymentStatus.transactionId}</p>
              <p>Amount: ₹{(paymentStatus.amount / 100).toFixed(2)}</p>
              <p>UPI Transaction ID: {paymentStatus.paymentInstrument.upiTransactionId}</p>
            </div>
          </>
        ) : (
          <>
            <h1>Payment Failed</h1>
            <p>Sorry, your payment could not be processed. Please try again.</p>
          </>
        )}
        <button className="go-back-button" onClick={() => (window.location.href = 'https://ctskola.io/wallet')}>
          Go Back
        </button>
      </div>
    </div>
  );
};

export default AfterPaymentSuccess;
