import React, { useState,useEffect } from "react";
import axios from "axios";
import { useParams } from 'react-router-dom';

import { FaEnvelope, FaMobileAlt, FaDollarSign } from "react-icons/fa"; // Importing icons
import './CheckoutForm.css'; // Your existing CSS file

export default function CheckoutForm() {

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false); // State to indicate loading status
  const { email } = useParams(); // Extract payment ID from the URL
  const [formData, setFormData] = useState({
    email: email,
    mobile: "",
    amount: ""
  });
  const [emailNotification, setEmailNotification] = useState("");

  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value
    }));
  };

  const checkEmail = async (email) => {
    try {
      const response = await axios.get(`https://api.ctskola.io/api/checkemail/${email}`);
      
      // Check if the status is true
      if (response.data.status) {
        localStorage.setItem("email", email); // Store the email in localStorage
        return true; // Email is valid
      } else {
        setErrorMessage("Email ID is not correct."); // Set error message if email is not correct
        return false; // Email does not exist
      }
    } catch (error) {
      console.error("Error checking email:", error);
      setErrorMessage("An error occurred while checking the email.");
      return false; // Return false if there was an error
    }
  };

  const handlePayment = async () => {
    const { email, mobile, amount } = formData;

    // Validate form data
    if (!email || !mobile || !amount || isNaN(amount) || amount <= 0) {
      setErrorMessage("Please fill out all fields correctly.");
      return;
    }
    if (amount <= 499) {
      setErrorMessage("Amount must be greater than 499.");
      return;
  }
    // Check email before proceeding with payment
    setLoading(true); // Set loading to true while checking email
    const isEmailValid = await checkEmail(email);
    setLoading(false); // Set loading to false after checking email

    // Only proceed to payment if the email is valid
    if (!isEmailValid) {
      return; // Stop if email is invalid
    }

    // Proceed with payment API call only if the email is valid
    try {
      const response = await axios.get(
        `https://api.rubyshoping.online/api/phonepedeposit/${amount}`
      );

      const paymentUrl = response.data.paymentUrl;

      if (paymentUrl) {
        window.location.href = paymentUrl;
      } else {
        setErrorMessage("Failed to generate payment URL. Please try again.");
      }
    } catch (error) {
      console.error("Error in payment:", error);
      setErrorMessage("An error occurred during the payment process. Please try again.");
    }
  };

  useEffect(() => {
    if (email) {
      setEmailNotification("Please ensure your email ID is correct before proceeding.");
    }
  }, [email]);
  return (
    <> {emailNotification && (
      <p className="email-notification">{emailNotification}</p>
    )}
    <div className="checkout-card">
        <h2>Payment Details</h2>
      <div className="input-group">
        <div className="input-with-icon">
          <FaEnvelope className="icon" />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            readOnly // Makes the email input non-editable
          />
        </div>
      </div>

      <div className="input-group">
        <div className="input-with-icon">
          <FaMobileAlt className="icon" />
          <input
            type="text"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            placeholder="Mobile"
          />
        </div>
      </div>

      <div className="input-group">
        <div className="input-with-icon">
          <FaDollarSign className="icon" />
          <input
            type="number"
            name="amount"
            value={formData.amount}
            onChange={handleChange}
            placeholder="Amount"
          />
        </div>
      </div>

      {errorMessage && <p className="error-message">{errorMessage}</p>}

      <button className="pay-button" onClick={handlePayment} disabled={loading}>
        {loading ? 'Processing...' : 'Pay'}
      </button>
    </div>
    </>
  );
}
